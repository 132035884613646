<template>
	<v-sheet height="100%" class="background">
		<v-container class="fill-height justify-center" fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" md="7">
					<v-sheet class="with-radius">
						<v-card-text class="pa-0">
							<v-row class="ma-0">
								<v-col cols="12">
									<div class="my-4 mx-auto text-center">
										<div class="text-h4 d-flex justify-center align-center">
											<img src="/static/default/logo-cloud.png" width="60" alt="logo" /><b
												>Franchising Cloud</b
											>
										</div>
										<div class="subtitle-1 mt-3">Activar cuenta</div>
									</div>
								</v-col>
								<v-col cols="12" md="6" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
									<v-row align="center" justify="center" class="fill-height ma-0">
										<v-img
											src="/static/illustator/welcome.png"
											lazy-src="/static/illustator/welcome.png"
										/>
									</v-row>
								</v-col>
								<!-- 2 -->
								<v-col cols="12" md="6">
									<v-sheet max-width="380" class="mx-auto" color="transparent">
										<v-form
											@submit.prevent="$v.$invalid ? null : submit()"
											ref="form"
											class="my-10"
										>
											<v-text-field
												:error-messages="fieldErrors('code')"
												@input="$v.code.$touch()"
												@blur="$v.code.$touch()"
												v-model="code"
												placeholder="000000"
												v-mask="'######'"
												masked="false"
												flat
											/>

											<v-btn
												block
												type="submit"
												color="primary"
												:disabled="$v.$invalid"
												:loading="loader"
												>Validar código</v-btn
											>
										</v-form>
									</v-sheet>
								</v-col>
							</v-row>
						</v-card-text>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
		<v-snackbar v-model="snackbar" absolute top right color="success">
			<span>Tu cuanta fue activada satisfactoriamente</span>
			<v-icon dark>check_circle</v-icon>
		</v-snackbar>
	</v-sheet>
</template>

<script>
	import { mask } from 'vue-the-mask'
	import { required, minLength, maxLength } from 'vuelidate/lib/validators'
	import validationMixin from '@/mixins/validationMixin'
	export default {
		directives: { mask },
		mixins: [ validationMixin ],
		validations: {
			code: { required, minLength, maxLength },
		},
		validationMessages: {
			code: {
				required: 'Por favor, introduce el código',
				minLength: minLength( 6 ),
				maxLength: maxLength( 6 ),
			},
		},
		data() {
			return {
				errors: null,
				code: null,
				loader: false,
				snackbar: false,
			}
		},
		methods: {
			submit() {
				this.loader = true
				this.active()
			},
			active() {
				this.$axios
					.get( 'auth/signup/activate/' + this.code )
					.then( () => {
						this.$swal( {
							title: '¡Hola!',
							html: 'Activaste tu cuenta satisfactoriamente.<br>Inicia sesión para disfrutar de nuestro Directorio de Franquicias',
							showCancelButton: false,
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						} )
						setTimeout( () => {
							this.loader = false
							this.$router.push( '/login' )
						}, 2000 )
					} )
					.catch( () => {
						this.loader = false
						this.error = true
					} )
			},
		},
	}
</script>
<style lang="scss">
	.background {
		background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg');
	}
</style>
